<!-- TODO: Denna ska visa kommande missions -->
<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query :query="query">
			<template #default="{ result: { loading, data } }">
				<template v-if="data">
					<a-invoice-late-alert
						v-if="data.invoices"
						:invoices="data.invoices"
					/>
					<a-invoice-upcoming-alert
						v-if="data.invoices"
						:invoices="data.invoices"
					/>

					<a-next-mission-card v-if="data.mission" :mission="data.mission" />
					<a-no-upcoming-mission-card v-else />

					<a-action-card v-bind="book" />

					<a-next-issue-card v-if="data.issue" :issue="data.issue" />
					<a-no-upcoming-issue-card v-else />

					<a-action-card v-bind="support" />
				</template>
				<template v-else>
					<a-loading :loading="loading" type="card-heading, list-item@3" />
					<a-loading :loading="loading" type="card-heading, list-item@3" />
				</template>
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import AActionCard from '@/components/ActionCard';
import ANextMissionCard from '@/components/NextMissionCard';
import ANoUpcomingMissionCard from '@/components/NoUpcomingMissionCard';
import ALoading from '@/components/Loading';
import AInvoiceLateAlert from '@/components/InvoiceLateAlert';
import AInvoiceUpcomingAlert from '@/components/InvoiceUpcomingAlert';
import ANextIssueCard from '@/components/NextIssueCard';
import ANoUpcomingIssueCard from '@/components/NoUpcomingIssueCard';
import { HOME_VIEW } from '@/graphql';

export default {
	name: 'Home',
	components: {
		ALoading,
		AActionCard,
		ANextMissionCard,
		ANoUpcomingMissionCard,
		AInvoiceLateAlert,
		AInvoiceUpcomingAlert,
		ANextIssueCard,
		ANoUpcomingIssueCard,
	},
	data: () => ({
		query: HOME_VIEW,
		book: {
			to: { name: 'book' },
			icon: 'mdi-plus',
			title: 'Bokningsförfrågan',
			text: 'Skicka en förfrågan om att boka våra tjänster',
			action: 'mdi-arrow-right',
		},
		support: {
			to: { name: 'company' },
			icon: 'mdi-contact-phone',
			title: 'Kundtjänst',
			text: 'Behöver du hjälp med någonting?',
			action: 'mdi-arrow-right',
		},
	}),
	metaInfo: {
		title: 'Hem',
	},
};
</script>

<style scoped></style>

<template>
	<v-card
		:to="{
			name: 'issue',
			params: { id: issue.id },
		}"
		class="mb-2"
	>
		<v-list-item three-line>
			<v-list-item-avatar class="mt-auto mb-auto" color="secondary">
				<v-icon dark>mdi-email</v-icon>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>Senaste meddelandet</v-list-item-title>
				<v-list-item-subtitle>
					{{ issue.subject }}
					<br />
					Status: {{ issue.status.text }}
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon class="mt-auto mb-auto">
				<v-icon>mdi-arrow-right</v-icon>
			</v-list-item-icon>
		</v-list-item>
	</v-card>
</template>

<script>
export default {
	name: 'NextIssueCard',
	props: {
		issue: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style></style>

<template>
	<v-card class="mb-2" color="primary">
		<v-container class="text-center">
			<v-icon size="100px" color="grey lighten-3">
				mdi-calendar-remove-outline
			</v-icon>
		</v-container>

		<v-card-subtitle class="pt-0 white--text text-center">
			Du har ingen inplanerad bokning
		</v-card-subtitle>
	</v-card>
</template>

<script>
export default {};
</script>

<style></style>

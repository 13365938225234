<template>
	<v-card
		:to="{
			name: 'booking',
			params: { id: mission.id },
		}"
		class="mb-3"
		color="primary"
		dark
	>
		<v-card-subtitle>Nästa bokning</v-card-subtitle>

		<v-card-text class="title pb-0 white--text">
			{{ getNextMissionDate() }}
			<br />
			<div class="subtitle-1">{{ getMissionTime() }}</div>
		</v-card-text>

		<v-list-item>
			<v-list-item-content>
				<v-list-item-title>
					{{ service() }}
				</v-list-item-title>
			</v-list-item-content>
			<v-list-item-icon>
				<v-icon>mdi-arrow-right</v-icon>
			</v-list-item-icon>
		</v-list-item>
	</v-card>
</template>

<script>
import missionMixin from '@/mixins/missionMixin';

export default {
	mixins: [missionMixin],
	props: {
		mission: {
			type: Object,
			required: true,
		},
	},
	methods: {
		service() {
			let result = `${this.mission.services[0].name}`;
			if (this.mission.services.length > 1) {
				this.mission.services.slice(1, -1).forEach(service => {
					result += `, ${service.name}`;
				});
				result += ` och ${this.mission.services.slice(-1)[0].name}`;
			}

			return result;
		},
		getNextMissionDate() {
			const dayName = this.$_missionMixin_missionDayName(this.mission);
			const date = this.$_missionMixin_missionDate(this.mission);
			const month = this.$_missionMixin_missionMonth(this.mission);
			return `${dayName}en den ${date} ${month}`;
		},
		getMissionTime() {
			return this.$_missionMixin_missionTime(this.mission);
		},
	},
};
</script>

<style></style>

import spacetime from 'spacetime';

const tz = 'Europe/Stockholm';

const missionMixin = {
	methods: {
		$_missionMixin_getStartDate(mission) {
			if (!mission || !mission.employees || mission.employees.length === 0) {
				return spacetime.now(tz);
			}

			return (
				mission.employees[0].startDate + 'T' + mission.employees[0].startTime
			);
		},
		$_missionMixin_getEndDate(mission) {
			if (!mission || !mission.employees || mission.employees.length === 0) {
				return spacetime.now(tz);
			}

			return (
				mission.employees[0].startDate + 'T' + mission.employees[0].endTime
			);
		},
		$_missionMixin_isBefore(compareThis, toThat) {
			const aDate = spacetime(new Date(compareThis), tz);
			const bDate = spacetime(new Date(toThat), tz);
			return aDate.isBefore(bDate);
		},
		$_missionMixin_missionDate(mission) {
			return this.$__mm_getStartSpacetime(mission).date();
		},
		$_missionMixin_missionMonth(mission) {
			const month = this.$__mm_getStartSpacetime(mission).month();
			return monthTranslator(month).toLowerCase();
		},
		$_missionMixin_missionYear(mission) {
			return this.$__mm_getStartSpacetime(mission).year();
		},
		$_missionMixin_missionDayName(mission) {
			const day = this.$__mm_getStartSpacetime(mission).day();
			return dayTranslator(day);
		},
		$_missionMixin_missionTime(mission) {
			const startTime = this.$__mm_getStartTime(mission);
			const endTime = this.$__mm_getEndTime(mission);
			return `${startTime} - ${endTime}`;
		},
		// Private functions that need access to mixin functions
		$__mm_getStartSpacetime(mission) {
			return spacetime(new Date(this.$_missionMixin_getStartDate(mission)), tz);
		},
		$__mm_getEndSpacetime(mission) {
			return spacetime(new Date(this.$_missionMixin_getEndDate(mission)), tz);
		},
		$__mm_getStartTime(mission) {
			const date = this.$__mm_getStartSpacetime(mission);
			return date.unixFmt('HH:mm');
		},
		$__mm_getEndTime(mission) {
			const date = this.$__mm_getEndSpacetime(mission);
			return date.unixFmt('HH:mm');
		},
	},
};

// Private functions
function dayTranslator(number) {
	switch (number) {
		case 0:
			return 'Söndag';
		case 1:
			return 'Måndag';
		case 2:
			return 'Tisdag';
		case 3:
			return 'Onsdag';
		case 4:
			return 'Torsdag';
		case 5:
			return 'Fredag';
		case 6:
			return 'Lördag';
		default:
			return '';
	}
}

function monthTranslator(number) {
	switch (number) {
		case 0:
			return 'Januari';
		case 1:
			return 'Februari';
		case 2:
			return 'Mars';
		case 3:
			return 'April';
		case 4:
			return 'Maj';
		case 5:
			return 'Juni';
		case 6:
			return 'Juli';
		case 7:
			return 'Augusti';
		case 8:
			return 'September';
		case 9:
			return 'Oktober';
		case 10:
			return 'November';
		case 11:
			return 'December';
		default:
			return '';
	}
}

export default missionMixin;

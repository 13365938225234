<template>
	<v-card class="mb-2">
		<v-list-item three-line>
			<v-list-item-avatar class="mt-auto mb-auto" color="grey">
				<v-icon dark>mdi-email</v-icon>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>Senaste meddelandet</v-list-item-title>
				<v-list-item-subtitle>
					Inga meddelanden
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-card>
</template>

<script>
export default {};
</script>

<style></style>

<template>
	<v-card
		v-if="oldestDeprecatedInvoice"
		:to="{
			name: 'invoice',
			params: { id: oldestDeprecatedInvoice.invoice.id },
		}"
	>
		<v-alert type="error" prominent border="left" class="mt-0 mb-3">
			Faktura förföll för {{ oldestDeprecatedInvoice.age }} dagar sedan.
		</v-alert>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';

export default {
	name: 'InvoiceLateAlert',
	props: {
		invoices: {
			type: Array,
			required: true,
		},
	},
	computed: {
		dueInvoices() {
			return this.invoices.filter(invoice => !this.isPaid(invoice));
		},
		deprecatedInvoices() {
			return this.dueInvoices.filter(invoice =>
				spacetime(invoice.date.due, 'Europe/Stockholm').isBefore(
					spacetime.now('Europe/Stockholm'),
				),
			);
		},
		oldestDeprecatedInvoice() {
			if (this.deprecatedInvoices.length === 0) return false;
			const oldest = this.deprecatedInvoices.reduce((oldest, next) =>
				spacetime(oldest.date.due, 'Europe/Stockholm').isBefore(next.date.due)
					? oldest
					: next,
			);
			return {
				// Set now time to 12.00am since date.due time is always 12.00am (does not contain any time)
				age: spacetime(oldest.date.due, 'Europe/Stockholm').diff(
					spacetime.now('Europe/Stockholm').time('12:00am'),
					'day',
				),
				invoice: oldest,
			};
		},
	},
	methods: {
		isPaid(invoice) {
			const { paid } = invoice;
			return paid === 'Y' || paid === 'O';
		},
	},
};
</script>

<style></style>

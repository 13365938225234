<template>
	<v-card
		v-if="issueClosestToDeprecation"
		:to="{
			name: 'invoice',
			params: { id: issueClosestToDeprecation.invoice.id },
		}"
		class="mb-2"
	>
		<v-alert
			:type="issueClosestToDeprecation.days > 7 ? 'info' : 'warning'"
			prominent
			border="left"
			class="mt-0 mb-3"
		>
			Nästa faktura förfaller om {{ issueClosestToDeprecation.days }} dagar.
		</v-alert>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';

export default {
	name: 'InvoiceUpcomingAlert',
	props: {
		invoices: {
			type: Array,
			required: true,
		},
	},
	computed: {
		dueInvoices() {
			return this.invoices.filter(invoice => !this.isPaid(invoice));
		},
		issueClosestToDeprecation() {
			const upcomingInvoices = this.dueInvoices.filter(invoice =>
				spacetime(invoice.date.due, 'Europe/Stockholm').isAfter(
					spacetime.now('Europe/Stockholm'),
				),
			);

			if (upcomingInvoices.length === 0) return false;

			const closestToDeprecation = upcomingInvoices.reduce((closest, next) =>
				spacetime(closest.date.due, 'Europe/Stockholm').isBefore(
					spacetime(next.date.due, 'Europe/Stockholm'),
				)
					? closest
					: next,
			);

			return {
				// Set now time to 12.00am since date.due time is always 12.00am (does not contain any time)
				days: spacetime
					.now('Europe/Stockholm')
					.time('12:00am')
					.diff(closestToDeprecation.date.due, 'day'),
				invoice: closestToDeprecation,
			};
		},
	},
	methods: {
		isPaid(invoice) {
			const { paid } = invoice;
			return paid === 'Y' || paid === 'O';
		},
	},
};
</script>

<style></style>

<template>
	<v-card :to="to" class="mb-2">
		<v-list-item three-line>
			<v-list-item-avatar class="mt-auto mb-auto" color="secondary">
				<v-icon dark>{{ icon }}</v-icon>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{ title }}</v-list-item-title>
				<v-list-item-subtitle>{{ text }}</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon class="align-self-center">
				<v-icon>{{ action }}</v-icon>
			</v-list-item-icon>
		</v-list-item>
	</v-card>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		action: {
			type: String,
			required: true,
		},
		to: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style></style>
